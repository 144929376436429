import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { StyledDiv } from './OverallPortfolioChart.styles';
import { Toggle } from '@sede-x/shell-ds-react-framework';
import Chart from '../../Chart/Chart';
import { fetchCarbonBudget } from './utils';
import { useNavigate } from 'react-router-dom';
import {
  ChartContainer,
  ToggleButton,
  ToggleButtonDiv,
  ToggleText,
  ViewDetailsDiv,
} from './index.styles';
import { ShellFont, makeNumberFromDigits, toolTipFormatter, typeBasedChart } from '../../../utils';
import { CHART_AXIS_STYLE, five, POWER_DISCLAIMER, zero } from '../../../constants';
import { StyledContainer } from '../ScenerioModellingChart/index.styles';
import ViewDetailsButton from '../../ViewDetailsButton';
import { PointsData, UniqueYearType } from '../../../Types/types';
import { useAppSelector } from '../../../redux/hooks';
import { getActualLastYearIndex } from '../../CommodityOverview/Utils';

export interface CarbonBudgetLineData {
  TRADE_COMMODITY_NAME: string;
  EVP: string | undefined;
  IPU: string | undefined;
  PORTFOLIO: string | undefined;
  TRADE_YEAR: number;
  SOURCE: string;
  CARBON_EMISSION: number;
  CARBON_EMISSION_UNIT: string;
  QUANTITY: number;
  UOM: string;
  CARBON_BUDGET: number;
}

interface OverallPortfolioChartProps {
  carbonBudgetLineData: CarbonBudgetLineData[];
}
interface ChartInsideData {
  CARBON_EMISSION?: number;
  CARBON_BUDGET?: number;
  QUANTITY?: number;
  SOURCE?: string;
  TRADE_COMMODITY_NAME: string;
  TRADE_YEAR?: number;
  type: string;
  year?: number;
  yearType?: string;
}

interface CarbonBudgetData {
  CARBON_BUDGET: number;
  CARBON_EMISSION: number;
  CARBON_EMISSION_UNIT: string;
  QUANTITY: number;
  SOURCE: string;
  TRADE_COMMODITY_NAME: string;
  TRADE_YEAR: number;
  UOM: string;
}

interface ChartData {
  type: string;
  name: string;
  data: ChartInsideData[];
  color?: string;
  dashStyle?: string;
}

const OverallPortfolioChart: React.FC<OverallPortfolioChartProps> = ({ carbonBudgetLineData }) => {
  const navigate = useNavigate();
  const { isUserGlobalOrSetAsia, isUserGlobalButNcfGlobal } = useAppSelector(
    (state) => state.dashboard,
  );
  const [uniqueYears, setUniqueYears] = useState<UniqueYearType[]>([]);
  const [uniqueSources, setUniqueSources] = useState<string[]>([]);
  const [updatedGroupedData, setUpdatedGroupedData] = useState<ChartData[]>([]);
  const [showEmissionChart, setShowEmissionChart] = useState(true);
  const [carbonBudgetData, setCarbonBudgetData] = useState<CarbonBudgetData[]>([]);
  const point5 = 0.5;

  const PORTFOLIO_REDIRECT = '/portFolioDetails';

  useEffect(() => {
    if (carbonBudgetData.length > 0) {
      const {
        uniqueSources: newUniqueSources,
        uniqueYears: newUniqueYears,
        groupedData: newGroupedData,
      } = fetchCarbonBudget(carbonBudgetData);
      setUniqueSources(newUniqueSources);
      setUniqueYears(newUniqueYears);
      setUpdatedGroupedData(newGroupedData);
    }
  }, [carbonBudgetData]);

  useEffect(() => {
    fetchCarbonBudget([]);
    setUniqueSources([]);
    setUniqueYears([]);
    setUpdatedGroupedData([]);
    setCarbonBudgetData([]);
  }, []);

  useEffect(() => {
    setUpdatedGroupedData([]);
    setCarbonBudgetData([]);
    const updatedCarbonBudgetData = carbonBudgetLineData.map((data) => {
      return data;
    });
    setCarbonBudgetData(updatedCarbonBudgetData);
  }, [carbonBudgetLineData]);

  const [indexOfLastActuals, setIndexOfLastActuals] = useState(-1);
  useEffect(() => {
    setIndexOfLastActuals(getActualLastYearIndex(uniqueYears));
  }, [updatedGroupedData]);

  const options: Highcharts.Options = {
    chart: {
      type: 'area',
      height:
        location && location.pathname !== PORTFOLIO_REDIRECT
          ? makeNumberFromDigits([five, zero, zero])
          : makeNumberFromDigits([five, five, zero]),
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    title: {
      text: 'Portfolio Overview',
      align: 'left',
      y: 20,
    },
    subtitle: {
      align: 'left',
      text: `${!showEmissionChart ? 'Net Absolute Emission' : 'Sales Volume'} Actuals vs Plan.`,
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px', // Adjust the font size here as per your requirement
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: !showEmissionChart ? 'Carbon Emission (MTPA)' : 'Volume (TWh)',
        style: CHART_AXIS_STYLE,
      },
    },

    xAxis: {
      categories: uniqueYears.map((x: { year: number }) => x.year.toString()),
      plotBands:
        indexOfLastActuals !== -1
          ? [
              {
                color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
                from: -point5,
                to: indexOfLastActuals,
                label: {
                  text: 'Actuals',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },

              {
                color: 'white', // Gray background for historical section
                from: indexOfLastActuals,
                label: {
                  text: 'Plan',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },
            ]
          : [
              {
                color: 'white', // Gray background for historical section
                from: -point5,
                to: uniqueYears.length - 1 + point5, // To final year
                label: {
                  text: 'Plan',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },
            ],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series:
      updatedGroupedData && updatedGroupedData.length > 0
        ? updatedGroupedData.map(
            // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/no-explicit-any
            (data) => {
              return data.type === 'area' || data.type === 'line'
                ? typeBasedChart(data, showEmissionChart)
                : ({} as Highcharts.SeriesOptionsType);
            },
          )
        : [
            {
              type: 'area',
              name: 'x.name',
              data: [],
            } as Highcharts.SeriesAreaOptions,
          ],
  };
  const toggleEmissionChart = () => {
    setShowEmissionChart(!showEmissionChart);
  };

  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    refreshChart();
  }, [uniqueSources]);

  return (
    <div>
      <StyledContainer>
        <ViewDetailsDiv>
          {location && location.pathname !== PORTFOLIO_REDIRECT && (
            <ViewDetailsButton handleClick={() => navigate(PORTFOLIO_REDIRECT)} float='right' />
          )}
        </ViewDetailsDiv>
        <ToggleButtonDiv>
          <ToggleButton>
            <Toggle
              label='Volume'
              mirrored
              data-testid='toggleButton-overallPortfolio'
              onChange={() => toggleEmissionChart()}
            />
          </ToggleButton>
          <ToggleText>Emissions</ToggleText>
        </ToggleButtonDiv>
        <StyledDiv>
          <ChartContainer>
            <Chart
              highcharts={Highcharts}
              options={options}
              source={uniqueSources.join(', ')}
              data-testid='chart'
              key={chartKey}
              chartKey='OverallPortfolio'
              {...(isUserGlobalOrSetAsia && { disclaimer: [POWER_DISCLAIMER] })}
            />
          </ChartContainer>
        </StyledDiv>
      </StyledContainer>
    </div>
  );
};

export default OverallPortfolioChart;
