import { Country, IAnalyticsHomeData } from '../../Types/types';

export const AnalyticsHomeInitialState: IAnalyticsHomeData = {
  ncfByCountry: [
    {
      UNIT: '',
      QTY: 0,
      COUNTRY_NAME: '',
      COUNTRY_CODE: '',
    },
  ],
  ncfQuarterwise: [
    {
      QUARTER: '',
      YEAR: 0,
      Original_Transaction_Type: '',
      UNIT: '',
      QTY: 0,
      TRADE_CODE: '',
      REGION: '',
      DATE: '',
    },
  ],
  ncfMonthWise: [
    {
      DATE: '',
      Original_Transaction_Type: '',
      UNIT: '',
      QTY: 0,
      TRADE_CODE: '',
      REGION: '',
    },
  ],
  cptyFilteredValues: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    transactionType: 'NCF',
    uom: 'TWh',
    ncfScope: [],
    commodity: ['PW'],
  },
  cptyFilterOptions: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    transactionType: [],
    unitName: [],
    ncfScope: [],
    commodity: [],
  },
  counterpartyData: [
    {
      CounterpartyName: '',
      Country: '',
      NcfCategory: '',
      Percentage: '',
      Quantity: 0,
      Year: 0,
    },
  ],
  counterpartyNcfCategoryData: [
    {
      NcfCategory: '',
      Country: '',
      Percentage: '',
      Quantity: 0,
      Year: 0,
    },
  ],
  counterpartyCountryData: [
    {
      NcfCategory: '',
      Country: '',
      Percentage: '',
      Quantity: 0,
      Year: 0,
    },
  ],
  filterOptions: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    regionCountryMapping: {} as Record<string, Country[]>,
    commodity: [],
    transactionType: [],
    unitName: [],
    ncfScope: [],
  },
  filteredValues: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    countryCode: [],
    commodity: ['PW'],
    uom: 'TWh',
    ncfType: 'CUMULATIVE',
    transactionType: 'NCF',
    ncfScope: [],
  },
  regionVolumeDetailsTableData: [
    {
      YEAR: 0,
      QUARTER: '',
      MONTH: '',
      COMMODITY: '',
      TRANSACTION_TYPE: '',
      ORIGINAL_TRANSACTION_TYPE: '',
      NCF_CATEGORY: '',
      CPTY: '',
      CPTY_DESC: '',
      QTY: 0,
    },
  ],
  isLoading: false,
  volumeByRegionChartData: {
    AMERICAS: [
      {
        PARAMETER_NAME: '',
        QTY: 0,
        isSum: false,
        color: '',
      },
    ],
  },
  volumeByRegionTableData: {
    AMERICAS: {
      data: [
        {
          YEAR: '',
          QUARTER: 1,
          MONTH: '',
        },
      ],

      totals: {
        TestParameter1: 0,
        TestParameter2: 0,
      },
    },
  },
  detailedViewParameter: {
    parameterName: '',
    region: '',
  },
};

export type IAnalyticsHomeDataState = typeof AnalyticsHomeInitialState;
