import styled from 'styled-components';
import { Button } from '@sede-x/shell-ds-react-framework';

export const MapContainer = styled.div`
  margin-bottom: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // height: 800px;
`;

export const Title = styled.div`
  font-family: 'Shell Font';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 6px;
`;

export const Spacer = styled.div`
  height: 15px;
  width: 100%;
`;

export const MapDiv = styled.div`
  height: 330px;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 360px;
  align-self: flex-end;
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  && {
    margin-right: 10px;
  }
`;

export const GeoChartContainer = styled.div`
  height: 100%; // reduce the height by 5 percent
  width: 100%;
`;
