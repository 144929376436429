import React, { useState, useContext } from 'react';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import { SectionHeader, TableHeading, ChartFooterText } from './RegionVolumeChart.style';
import {
  getCommodityName,
  generateColumns,
  WHOLESALE_CHURN_PARAMETER_NAME,
  OUT_OF_SCOPE_VOLUME_PARAMETER_NAME,
  useFetchData,
  useChartOptions,
} from './RegionVolumeChartUtils';
import Chart from '../../../Chart/Chart';
import { BaseTable, Flexbox, Grid, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { SingleRegionVolumeTableData } from '../../../../../../Types/types';
import Loader from '../../../../../Loader/Loader';
import { Link } from 'react-router-dom';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
interface VolumeChartProps {
  region: string;
}

const SingleRegionVolumeChart: React.FC<VolumeChartProps> = ({ region }) => {
  const [selectedParameter, setSelectedParameter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const { state: ciTradeAndVolumeState } = useContext(AppContext).analyticsHome;
  const { isLoading } = useFetchData(region);
  const tabledata: SingleRegionVolumeTableData[] =
    ciTradeAndVolumeState.volumeByRegionTableData[region]?.data;
  const tableTotals = ciTradeAndVolumeState.volumeByRegionTableData[region]?.totals;

  const onSelectWaterfallBar = (parameter: string) => {
    setSelectedParameter(parameter);
    analyticsDispatch({
      type: 'setAnalyticsHomeDetailedViewParameter',
      payload: {
        parameterName: parameter,
        region,
      },
    });
  };
  const options = useChartOptions(region, selectedParameter, onSelectWaterfallBar);

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  const dbParameterName =
    selectedParameter === WHOLESALE_CHURN_PARAMETER_NAME
      ? OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
      : selectedParameter;
  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else {
      return (
        <Flexbox gap='10px'>
          <Flexbox
            style={{
              flex: '1 1 50%',
              maxWidth: '50%',
              overflow: 'auto',
              backgroundColor: '#fff',
              marginBottom: '10px',
            }}
          >
            <Grid gap='10px'>
              <Grid.Cell>
                <Chart options={options} footer={'Source'} />
              </Grid.Cell>
              <Grid.Cell>
                <Flexbox
                  justifyContent='space-between'
                  alignItems='center'
                  style={{ minHeight: '30px' }}
                >
                  <ChartFooterText>Select a parameter to drill through</ChartFooterText>
                  <Button disabled={!dbParameterName} variant='outlined' size='small'>
                    <Link to={`/region-volume-details`} style={{ textDecoration: 'none' }}>
                      View Details
                    </Link>
                  </Button>
                </Flexbox>
              </Grid.Cell>
            </Grid>
          </Flexbox>
          <Flexbox
            style={{
              flex: '1 1 50%',
              maxWidth: '50%',
              overflow: 'auto',
              backgroundColor: '#fff',
              marginBottom: '10px',
            }}
          >
            <BaseTable
              columns={generateColumns(tableTotals)}
              data={tabledata}
              size='large'
              data-testid='base-table'
              stickyHeader
              maxHeight={400}
              tableOptions={tableOptions}
              width={'100%'}
              stickyFooter={true}
            />
          </Flexbox>
        </Flexbox>
      );
    }
  };

  return (
    <div data-testid='single-region-volume-chart'>
      <SectionHeader>
        <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
          <TableHeading>
            {getCommodityName(analyticsState?.filteredValues?.commodity[0])} Volume For {region}
          </TableHeading>
          <Flexbox justifyContent='flex-end' alignItems='center' style={{ flex: 1 }}>
            <DownloadCsvButton data={tabledata} fileName={'NcfVolume'} />
          </Flexbox>
        </Flexbox>
      </SectionHeader>
      {renderContent()}
    </div>
  );
};

export default SingleRegionVolumeChart;
