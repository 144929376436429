import { PointsData } from '../../../Types/types';
import { TITLE_FONT_FAMILY, CHART_AXIS_STYLE, point5, pipeLineGas } from '../../../constants';
import { ChartLegendStyle, toolTipFormatter, ChartAxisStyle } from '../../../utils';
import { getActualLastYearIndex } from '../../CommodityOverview/Utils';
import { GroupedDataItem, TargetDataItem, YearProcessedDataItem } from './types';
import Highcharts from 'highcharts';

const actuals = 'actuals';
const line = 'line';
const left = 'left';
interface ChartData {
  type?: string;
  color?: string;
  name: string;
  data: TargetDataItem[] | GroupedDataItem[];
  marker?: {
    symbol: string;
    lineWidth: number;
    lineColor: string;
  };
}
interface HighchartOptions {
  commodityVal: string;
  resultYearArray: YearProcessedDataItem[];
  firstNotNullIndex: number;
  newarray: ChartData[];
}
export const highchartOptions = (highchartData: HighchartOptions) => {
  const { commodityVal, resultYearArray, newarray } = highchartData;
  const title = commodityVal === pipeLineGas ? 'Gas' : commodityVal;
  const indexOfLastActuals = getActualLastYearIndex(resultYearArray);
  const options: Highcharts.Options = {
    chart: {
      type: line,
      marginBottom: 100,
    },
    title: {
      text: `${title} Carbon Intensity`,
      align: left,
      x: 16,
      y: 20,
      style: {
        fontSize: '20px',
        fontFamily: TITLE_FONT_FAMILY,
      },
    },
    legend: {
      x: 16,
      itemStyle: ChartLegendStyle,
      align: left,
    },
    subtitle: {
      text: 'Carbon Intensity Actuals vs Plan.',
      align: left,
      x: 16,
      style: {
        fontSize: '14px',
        fontFamily: TITLE_FONT_FAMILY,
      },
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          x: 5,
          y: -10,
          menuItems: ['downloadPNG', 'downloadSVG'],
        },
      },
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: 'tCO2e/MWh',
      },
      gridLineWidth: 1,
      min: 0,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
        },
      },
    },
    xAxis: {
      categories: resultYearArray.map((x: YearProcessedDataItem) => x.year),
      plotBands:
        indexOfLastActuals !== -1
          ? [
              {
                color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
                from: -point5,
                to: indexOfLastActuals,
                label: {
                  text: 'Actuals',
                  style: ChartAxisStyle,
                  verticalAlign: 'bottom',
                  y: 40,
                },
              },
              {
                color: 'rgba(255, 255, 255, 0.2)',
                from: indexOfLastActuals,
                label: {
                  text: 'Plan',
                  style: ChartAxisStyle,
                  verticalAlign: 'bottom',
                  y: 40,
                },
              },
            ]
          : [
              {
                color: 'rgba(255, 255, 255, 0.2)',
                from: -point5,
                to: resultYearArray.length - 1 + point5, // To final year
                label: {
                  text: 'Plan',
                  style: ChartAxisStyle,
                  verticalAlign: 'bottom',
                  y: 40,
                },
              },
            ],
    },

    series: newarray
      .filter((item) => item.name !== 'Target Region (Global)')
      .map((dataObj: ChartData) => ({
        type: line,
        dashStyle: 'Dash',
        name: dataObj.name,
        data:
          dataObj?.data instanceof Array
            ? dataObj.data.map((dataPoint: TargetDataItem | GroupedDataItem) => ({
                y: dataPoint.total_value,
                tooltipText: `${dataPoint.type === actuals ? '(Actual)' : ''}`, // Use the custom tooltip text
              }))
            : [],
        color: 'red', // Set the line color to red
      })),
  };
  return options;
};
