import React, { useContext, useState } from 'react';
import {
  BaseTable,
  Button,
  Divider,
  Flexbox,
  Pagination,
  Sizes,
} from '@sede-x/shell-ds-react-framework';
import { getPaginationRowModel } from '@tanstack/react-table';
import { AppContext } from '../../../../../../Context/AppContext';
import DeltaTableTotalValues from '../DeltaTableTotalValues/DeltaTableTotalValues';
import DeltaTableFilters from '../DeltaTableFilters/DeltaTableFilters';
import { ChartContainer, TableHeading } from './DeltaTable.style';
import { reconDownloadVolume } from '../../../../../../api/services';
import { notify } from '../../../../../../../CarbonDashboard/components/Toast/notify';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
import Loader from '../../../../../Loader/Loader';
import {
  ArrowDown,
  ArrowUp,
  DownloadCloud,
} from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import {
  IReconReportItemCpm,
  IReconReportNcfCategoryMismatchTableData,
} from '../../../../../../Types/types';

type SortDirection = 'asc' | 'desc' | null;

const DeltaTable: React.FC = () => {
  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;
  const [activeFilter, setActiveFilter] = useState<string | null>('DELTA_DETAILS');
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ column: string; direction: SortDirection }>({
    column: '',
    direction: null,
  });

  const handleButtonClick = (buttonName: string) => {
    setActiveFilter(buttonName);
    const payload = {
      ...reconState.ReconfilteredValues,
      reportType: buttonName,
    };
    reconDispatch({ type: 'setReconFilteredValues', payload });
  };

  const defaultData = reconState.reconReportVolumeTableData.data || [];
  const filteredData = reconState.reconReportNcfCategoryMismatchTableData || [];
  const totalPages = reconState.reconReportVolumeTableData.totalPages || 0;

  const handleSort = (column: string) => {
    let direction: 'asc' | 'desc' | null = 'asc';
    if (sortConfig.column === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.column === column && sortConfig.direction === 'desc') {
      direction = null;
    }
    setSortConfig({ column, direction });

    if (direction === null) {
      updateConfigRulePaginationData(1);
    } else {
      updateConfigRulePaginationData(1, column, direction);
    }
  };

  const updateConfigRulePaginationData = (
    pageNumber: number,
    sortColumn?: string,
    sortDirection?: 'asc' | 'desc' | null,
  ) => {
    const payload = {
      ...reconState.ReconfilteredValues,
      pageNumber,
      sortColumn,
      sortDirection,
    };
    reconDispatch({ type: 'setReconFilteredValues', payload });
  };

  const getSortIcon = (key: string) => {
    if (sortConfig.column === key) {
      if (sortConfig.direction === 'asc') {
        return <ArrowUp />;
      } else if (sortConfig.direction === 'desc') {
        return <ArrowDown />;
      }
    }
    return null;
  };

  const renderHeaderButton = (key: string) => (
    <button
      onClick={() => handleSort(key)}
      style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
      aria-label={`Sort by ${key.replace(/_/g, ' ')}`}
    >
      {key.replace(/_/g, ' ')}
      {getSortIcon(key)}
    </button>
  );

  const getColumns = (data: IReconReportNcfCategoryMismatchTableData[] | IReconReportItemCpm[]) => {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).map((key) => ({
      header: () => renderHeaderButton(key),
      accessorKey: key,
    }));
  };

  const defaultColumns = getColumns(defaultData);
  const filteredColumns = getColumns(filteredData);

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
  };

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const response = await reconDownloadVolume(reconState.ReconfilteredValues);
      // Ensure the response data is a Blob of type 'text/csv'
      const blob = new Blob([response.data], { type: 'text/csv' });
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', activeFilter + '.csv'); // Set the file name
      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notify('error', 'Error downloading the CSV file');
    }
  };

  const downloadButton =
    activeFilter === 'NCF_CATEGORY_MISMATCH' ? (
      <DownloadCsvButton data={filteredData} fileName={'NcfCategoryMismatch'} />
    ) : (
      <Button
        icon={<DownloadCloud />}
        iconOnly
        size={Sizes.Medium}
        onClick={handleDownload}
        variant='transparent'
      >
        Download
      </Button>
    );

  return (
    <div>
      <Loader loader={isLoading} message={''} />
      <DeltaTableFilters activeFilter={activeFilter} onFilterClick={handleButtonClick} />
      <ChartContainer data-testid='map-container'>
        <Flexbox justifyContent='space-between'>
          <TableHeading data-testid='delta-details'>
            {activeFilter === 'NCF_CATEGORY_MISMATCH' ? 'NCF Category Mismatch' : 'Delta Details'}
          </TableHeading>
          {downloadButton}
        </Flexbox>
        <Divider size='medium' direction='horizontal' />
        <div>
          {activeFilter === 'NCF_CATEGORY_MISMATCH' ? (
            <div>
              <BaseTable
                columns={filteredColumns}
                data={filteredData}
                size='large'
                data-testid='filtered-table'
                style={{ width: '100%', border: '1px solid #C0C0C0', borderRadius: '10px' }}
                className='custom-header'
                tableOptions={tableOptions}
              />
            </div>
          ) : (
            <div>
              <DeltaTableTotalValues />
              <BaseTable
                columns={defaultColumns}
                data={defaultData}
                size='large'
                data-testid='delta-details'
                style={{ width: '100%', border: '1px solid #C0C0C0', borderRadius: '10px' }}
                className='custom-header'
              />
              {defaultData.length > 0 && (
                <Pagination
                  data-testid='pagination'
                  total={totalPages}
                  pageSize={10}
                  onChange={(pageNumber: number) =>
                    updateConfigRulePaginationData(
                      pageNumber,
                      sortConfig.column || 'YEAR',
                      sortConfig.direction ?? 'desc',
                    )
                  }
                />
              )}
            </div>
          )}
        </div>
        <div></div>
      </ChartContainer>
    </div>
  );
};

export default DeltaTable;
