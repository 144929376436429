import { API_VERSION_ONE, API_VERSION_TWO } from './constants';
import {
  IAuditPostData,
  IConfigRuleNewRecord,
  IEditAuditFilterOptions,
  IFetchSnapshotDataOption,
  IOptions,
  IppaInsightFilteredValues,
  RuleConfigDataI,
} from '../Types/types';
import { axiosInstanceTanso } from '../../commonUtils/apiAxiosInstance/axiosInstance';

export const fetchReport = (method: string, options: IEditAuditFilterOptions | IAuditPostData) => {
  const getUrl = `${API_VERSION_ONE}records/?pageNumber=${
    (options as IEditAuditFilterOptions).pageNumber
  }&pageSize=${(options as IEditAuditFilterOptions).pageSize}&deliveryMonthStart=${
    (options as IEditAuditFilterOptions).firstDate
  }&deliveryMonthEnd=${(options as IEditAuditFilterOptions).lastDate}&transactionType=${
    (options as IEditAuditFilterOptions).transactionType
  }&commodity=${(options as IEditAuditFilterOptions).commodity}&counterparty=${
    (options as IEditAuditFilterOptions).cptyDesc
  }&tableName=${options.tableName}`;
  const postUrl = `${API_VERSION_ONE}records/`;
  let result;
  if (method === 'get') {
    result = axiosInstanceTanso.get(getUrl);
  } else if (method === 'put') {
    result = axiosInstanceTanso.put(postUrl, options as IAuditPostData);
  }
  return result;
};

export const fetchMarketOptions = (tableName: string) => {
  const url = `${API_VERSION_ONE}markets/?tableName=${tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchNcfCategoriesOptions = (tableName: string) => {
  const url = `${API_VERSION_ONE}ncf-categories/?tableName=${tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchNcfCategoriesForAsiaAndAmericaRegion = (region: string) => {
  const url = `${API_VERSION_TWO}ncf-categories-by-region/?region=${region}`;
  return axiosInstanceTanso.get(url);
};

export const fetchNcfCategoriesForAsiaAndAmericaRegionV1 = (region: string) => {
  const url = `${API_VERSION_ONE}ncf-categories-by-region/?region=${region}`;
  return axiosInstanceTanso.get(url);
};

export const fetchAuditReport = (options: IEditAuditFilterOptions) => {
  const url = `${API_VERSION_ONE}audit-logs/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&startDate=${options.startDate}&endDate=${options.endDate}&region=${options.region}`;
  return axiosInstanceTanso.get(url);
};

export const fetchCommodities = (tableName: string) => {
  const url = `${API_VERSION_ONE}commodities/?tableName=${tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchCptyForAsiaRegion = (region: string) => {
  const url = `${API_VERSION_ONE}counterparties-by-region/?region=${region}`;
  return axiosInstanceTanso.get(url);
};

export const fetchCpty = (options: { pageNumber: number; pageSize: number; tableName: string }) => {
  const url = `${API_VERSION_ONE}counterparties/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&tableName=${options.tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchCountryForSeamRegionTableEdit = (region: string) => {
  const url = `${API_VERSION_ONE}countries-by-region/?region=${region}`;
  return axiosInstanceTanso.get(url);
};

export const fetchCptySearchResult = (options: { tableName: string; keyword: string }) => {
  const url = `${API_VERSION_ONE}search-counterparties/?tableName=${options.tableName}&keyword=${options.keyword}`;
  return axiosInstanceTanso.get(url);
};

export const fetchMarketSearchResult = (options: { tableName: string; keyword: string }) => {
  const url = `${API_VERSION_ONE}search-markets/?tableName=${options.tableName}&keyword=${options.keyword}`;
  return axiosInstanceTanso.get(url);
};

export const fetchDealType = (tableName: string) => {
  const url = `${API_VERSION_ONE}deal-types/?tableName=${tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchRegion = () => {
  const url = `${API_VERSION_ONE}regions`;
  return axiosInstanceTanso.get(url);
};

export const fetchSnapshotData = (options: IFetchSnapshotDataOption) => {
  let url = `${API_VERSION_ONE}ncf-snapshots/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}`;
  if (options.region) {
    url += `&region=${options.region}`;
  }
  return axiosInstanceTanso.get(url);
};

export const generateNcfSnapshotName = (options: {
  region: string | undefined;
  commodity: string;
  year: string;
  timePeriod: string;
  unitOfTime: string;
  snapshotStatus?: string;
}) => {
  const url = `${API_VERSION_ONE}generate-ncf-snapshot/`;
  return axiosInstanceTanso.post(url, options);
};

export const updateSnapshotStatus = (options: {
  ncfSnapshotIds: number[];
  snapshotStatus: string;
}) => {
  const url = `${API_VERSION_ONE}ncf-snapshots-bulk-update/`;
  return axiosInstanceTanso.put(url, options);
};

export const fetchStorage = (tableName: string) => {
  const url = `${API_VERSION_ONE}storages/?tableName=${tableName}`;
  return axiosInstanceTanso.get(url);
};

export const fetchConfigRuleReport = (options: RuleConfigDataI) => {
  const url = `${API_VERSION_ONE}rule-configs/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&tradeType=${options.tradeType}&cptyShortName=${options.cptyShortName}`;
  return axiosInstanceTanso.get(url);
};

export const addConfigRule = (options: IConfigRuleNewRecord) => {
  const url = `${API_VERSION_ONE}rule-configs/`;
  return axiosInstanceTanso.post(url, options);
};

export const authorizeUser = (userid: number) => {
  const url = `${API_VERSION_ONE}user-profile/${userid}`;
  return axiosInstanceTanso.get(url);
};

export const deleteConfigRule = (id: number) => {
  const url = `${API_VERSION_ONE}rule-configs/${id}`;
  return axiosInstanceTanso.delete(url);
};

export const step01LogUrl = `${API_VERSION_ONE}sectoral-cpty-list-extract-log/`;
export const step02LogUrl = `${API_VERSION_ONE}sectoral-upload-mapping-sheets-log/`;
export const step03LogUrl = `${API_VERSION_ONE}sectoral-produce-output-log`;
export const downloadSecotrWiseNcfVolumes = `${API_VERSION_ONE}download-sector-wise-ncf-volumes`;
export const downloadCptyWiseNcfVolumes = `${API_VERSION_ONE}download-cpty-wise-ncf-volumes`;
export const downloadEntireCtpyListUrl = `${API_VERSION_ONE}download-mapped-cpty-list`;
export const downloadUnmappedCtpyListUrl = `${API_VERSION_ONE}download-unmapped-cpty-list`;
export const uploadSectoralUrl = `${API_VERSION_ONE}sectoral-file-upload/`;
export const lastRefeshUrl = `${API_VERSION_ONE}ncf-volume-last-refresh/`;
export const ncfByCountryUrl = `${API_VERSION_ONE}ncf-volume-by-country/`;
export const ncfQuarterWiseDataUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-quarter-wise-data/`;
export const ncfMonthWiseDataUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-month-wise-data/`;
export const ncfTableDataMonthlyUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-month-wise-table-data/`;

export const filterDataUrl = `${API_VERSION_ONE}ncf-volume-filter-data/`;
export const ciTradeChartAPIUrl = `${API_VERSION_ONE}ci-trade-chart-data/`;
export const ciTradeTableAPIUrl = `${API_VERSION_ONE}ci-trade-table-data/`;

export const topCounterPartyUrl = `${API_VERSION_ONE}analytics-top-cpty-data`;
export const topCounterPartyChartUrl = `${API_VERSION_ONE}analytics-top-cpty-chart-data`;
export const allCounterPartyUrl = `${API_VERSION_ONE}analytics-all-cpty-data`;
export const counterPartyFilterUrl = `${API_VERSION_ONE}analytics-filter-data`;
export const counterPartyNcfCategoryUrl = `${API_VERSION_ONE}analytics-cpty-ncf-category-data`;

export const volumeByRegionChartAPIUrl = `${API_VERSION_ONE}analytics-volume-by-region-chart-data/`;
export const volumeByRegionTableAPIUrl = `${API_VERSION_ONE}analytics-volume-by-region-table-data/`;
export const regionVolumeDetailsTableApiUrl = `${API_VERSION_ONE}analytics-volume-by-region-details/`;

export const biaValidationCheckApiUrl = `${API_VERSION_ONE}data-validation-check/`;
export const biaUncatCptyApiUrl = `${API_VERSION_ONE}get-uncategorised-cpty/`;
export const biaInactiveCptyApiUrl = `${API_VERSION_ONE}get-inactive-cpty-for-active-volume/`;
export const biaGroupAffApiUrl = `${API_VERSION_ONE}get-list-of-group-affiliate/`;

//Analytics snapshot APIs
const AnalyticsSnapshotReportLineChartApiUrl = `${API_VERSION_ONE}get-analytics-ncf-snapshot-line-chart-data/`;
const AnalyticsNcfSnapshotTableApiUrl = `${API_VERSION_ONE}get-analytics-ncf-snapshot-table-data/`;
const AnalyticsSnapshotVolumeByRegionChartApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-chart-data/`;
const AnalyticsSnapshotVolumeByRegionTableApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-table-data/`;
const AnalyticsSnapshotReportsApiUrl = `${API_VERSION_ONE}analytics-snapshot-reports/`;
const AnalyticsSnapshotVolumeByRegionDetailsApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-details/`;

// PPT APIs
const pptNcfVolumeUrl = `${API_VERSION_ONE}analytics-ppt-ncf-volumes/`;
const pptNcfCategoryUrl = `${API_VERSION_ONE}analytics-ppt-ncf-categories/`;
const pptReportsUrl = `${API_VERSION_ONE}ppt-reports/`;
const pptInScopeVsOutScopeVolumeUrl = `${API_VERSION_ONE}analytics-ppt-scope-volumes/`;

// Reconciliation Reports APIs
const reconReportTotalVolumeApiUrl = `${API_VERSION_ONE}recon-report-total-volume/`;
const reconReportVolumeTableDataApiUrl = `${API_VERSION_ONE}recon-report-volume-table-data/`;
const reconReportNcfCategoryMismatchTableDataApiUrl = `${API_VERSION_ONE}recon-report-ncf-category-mismatch-table-data/`;
const reconReportLastRefreshedDateApiUrl = `${API_VERSION_ONE}recon-report-last-refreshed-date/`;
const reconFilterOptionsApiUrl = `${API_VERSION_ONE}recon-report-filter/`;
const reconETRMFileUpload = `${API_VERSION_ONE}recon-etrm-raw-file-upload/`;
const reconETRMFileUploadLog = `${API_VERSION_ONE}recon-etrm-file-upload-log/`;

const reconDownloadReportApiUrl = `${API_VERSION_ONE}recon-download-report-volume/`;
/*
on load
sectoral-cpty-list-extract-log/ - for first card
sectoral-upload-mapping-sheets-log/ - for second card
sectoral-produce-output-log_ - for third card
*/
export const getStep01Log = () => {
  return axiosInstanceTanso.get(step01LogUrl);
};
export const getStep02Log = () => {
  return axiosInstanceTanso.get(step02LogUrl);
};
export const getStep03Log = () => {
  return axiosInstanceTanso.get(step03LogUrl);
};

export const downloadEntireCtpyList = () => {
  const url = downloadEntireCtpyListUrl;
  return axiosInstanceTanso.get(url);
};

export const downloadUnmappedCtpyList = (year: number, qtr: number) => {
  const url = `${downloadUnmappedCtpyListUrl}/?year=${year}&quarter=${qtr}`;
  return axiosInstanceTanso.get(url);
};

export const uploadSectoralFiles = (formData: FormData) => {
  const url = uploadSectoralUrl;
  return axiosInstanceTanso.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSecotrWiseNcfVol = (
  startYear: string,
  startMonth: string,
  endYear: string,
  endMonth: string,
) => {
  const url = `${downloadSecotrWiseNcfVolumes}/?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
  return axiosInstanceTanso.get(url);
};

export const downloadCptyWiseNcfVol = (
  startYear: string,
  startMonth: string,
  endYear: string,
  endMonth: string,
) => {
  const url = `${downloadCptyWiseNcfVolumes}/?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
  return axiosInstanceTanso.get(url);
};

export const lastRefesh = () => {
  const url = lastRefeshUrl;
  return axiosInstanceTanso.get(url);
};

export const ncfByCountry = (formData: object) => {
  const url = ncfByCountryUrl;
  return axiosInstanceTanso.post(url, formData, {});
};

export const ncfMonthWiseData = (formData: object) => {
  const url = ncfMonthWiseDataUrl;
  return axiosInstanceTanso.post(url, formData, {});
};

export const ncfQuarterWiseData = (formData: object) => {
  const url = ncfQuarterWiseDataUrl;
  return axiosInstanceTanso.post(url, formData, {});
};

export const ncfTableDataMonthly = (formData: object) => {
  const url = ncfTableDataMonthlyUrl;
  return axiosInstanceTanso.post(url, formData, {});
};

export const filterData = () => {
  const url = filterDataUrl;
  return axiosInstanceTanso.get(url);
};

export const ciTradeChartData = (formData: object) => {
  return axiosInstanceTanso.post(ciTradeChartAPIUrl, formData, {});
};

export const ciTradeTableData = (formData: object) => {
  return axiosInstanceTanso.post(ciTradeTableAPIUrl, formData, {});
};

export const topCounterPartyTable = (formData: object) => {
  return axiosInstanceTanso.post(topCounterPartyUrl, formData, {});
};

export const topCounterPartyChart = (formData: object) => {
  return axiosInstanceTanso.post(topCounterPartyChartUrl, formData, {});
};

export const counterPartyNcfCategory = (formData: object) => {
  return axiosInstanceTanso.post(counterPartyNcfCategoryUrl, formData, {});
};

export const allCounterParty = (formData: object) => {
  return axiosInstanceTanso.post(allCounterPartyUrl, formData, {});
};

export const counterPartyFilter = () => {
  return axiosInstanceTanso.get(counterPartyFilterUrl);
};

// APIs for PPA Insight
interface PPAOptions {
  pageNumber?: number;
  pageSize?: number;
  year?: number;
  quarter?: string[];
  region?: string[];
  renewableCategory?: string[];
  tenure?: number[];
  contractType?: string[];
}

export const generateUrl = (base: string, options: PPAOptions | IppaInsightFilteredValues) => {
  let url = `${API_VERSION_ONE}${base}/?`;
  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        url += `${key}=${val}&`;
      });
    } else if (value) {
      url += `${key}=${value}&`;
    }
  });
  return url.slice(0, -1); // Remove the trailing '&'
};

export const getPpaInsightFormFilterOptions = () => {
  const url = `${API_VERSION_ONE}ppa-filter-form-input-data`;
  return axiosInstanceTanso.get(url);
};

export const getPpaInsightVolumeByRegionData = (options: IOptions) => {
  const url = generateUrl('ppa-volume-by-region', options);
  return axiosInstanceTanso.get(url);
};

export const getPpaInsightLongVsShortTermData = (options: IOptions) => {
  const url = generateUrl('ppa-long-term-vs-short-term-deals', options);
  return axiosInstanceTanso.get(url);
};

export const getPpaInsightVolByTechTypeData = (options: IOptions) => {
  const url = generateUrl('ppa-volume-by-technology-type', options);
  return axiosInstanceTanso.get(url);
};

export const getPpaInsightTableData = (options: IOptions) => {
  const url = generateUrl('ppa-insights-table-data', options);
  return axiosInstanceTanso.get(url);
};

export const getPpaInsightExportData = (options: IOptions) => {
  const url = generateUrl('ppa-insights-export', options);
  return axiosInstanceTanso.get(url);
};

export const getVolumeByRegionChartData = (formData: object) => {
  return axiosInstanceTanso.post(volumeByRegionChartAPIUrl, formData, {});
};

export const getVolumeByRegionTableData = (formData: object) => {
  return axiosInstanceTanso.post(volumeByRegionTableAPIUrl, formData, {});
};

// END :: APIs for PPA Insight

// BIA reports APIs
export const getBiaValidationCheckData = (options: IOptions) => {
  return axiosInstanceTanso.post(biaValidationCheckApiUrl, options, {});
};

export const getBiaUncatCptyData = (options: IOptions) => {
  return axiosInstanceTanso.post(biaUncatCptyApiUrl, options, {});
};

export const getBiaInactiveCptyData = (options: IOptions) => {
  return axiosInstanceTanso.post(biaInactiveCptyApiUrl, options, {});
};

export const getBiaGroupAffData = (options: IOptions) => {
  return axiosInstanceTanso.post(biaGroupAffApiUrl, options, {});
};

export const regionVolumeDetailsTableData = (formData: object) => {
  return axiosInstanceTanso.post(regionVolumeDetailsTableApiUrl, formData, {});
};

export const getAnalyticsSnapshotReportLineChartData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsSnapshotReportLineChartApiUrl, formData, {});
};

export const getAnalyticsNcfSnapshotReportTableData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsNcfSnapshotTableApiUrl, formData, {});
};

export const getAnalyticsSnapshotVolumeByRegionChartData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsSnapshotVolumeByRegionChartApiUrl, formData, {});
};

export const getAnalyticsSnapshotVolumeByRegionTableData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsSnapshotVolumeByRegionTableApiUrl, formData, {});
};

export const getAnalyticsSnapshotReportsData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsSnapshotReportsApiUrl, formData, {});
};

export const getSnapshotRegionVolumeDetailsTableData = (formData: object) => {
  return axiosInstanceTanso.post(AnalyticsSnapshotVolumeByRegionDetailsApiUrl, formData, {});
};
// PPT Reporting APIs
export const pptNcfVolume = (formData: object) => {
  return axiosInstanceTanso.post(pptNcfVolumeUrl, formData, {});
};

export const pptNcfCategory = (formData: object) => {
  return axiosInstanceTanso.post(pptNcfCategoryUrl, formData, {});
};

export const pptReports = (formData: object) => {
  return axiosInstanceTanso.post(pptReportsUrl, formData, {});
};

export const pptInScopeVsOutScopeVolume = (formData: object) => {
  return axiosInstanceTanso.post(pptInScopeVsOutScopeVolumeUrl, formData, {});
};

// Reconcdiliation Reports APIs

export const reconReportTotalVolume = (formData: object) => {
  return axiosInstanceTanso.post(reconReportTotalVolumeApiUrl, formData, {});
};

export const reconReportVolumeTableData = (formData: object) => {
  return axiosInstanceTanso.post(reconReportVolumeTableDataApiUrl, formData, {});
};

export const reconReportNcfCategoryMismatchTableData = (formData: object) => {
  return axiosInstanceTanso.post(reconReportNcfCategoryMismatchTableDataApiUrl, formData, {});
};

export const reconReportLastRefreshedDate = (formData: object) => {
  return axiosInstanceTanso.post(reconReportLastRefreshedDateApiUrl, formData, {});
};

export const reconFilterOptions = (formData: object) => {
  return axiosInstanceTanso.post(reconFilterOptionsApiUrl, formData, {});
};

export const uploadETRMFile = (formData: FormData) => {
  return axiosInstanceTanso.post(reconETRMFileUpload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getReconETRMFileUploadLog = () => {
  return axiosInstanceTanso.get(reconETRMFileUploadLog);
};
export const reconDownloadVolume = (formData: object) => {
  return axiosInstanceTanso.post(reconDownloadReportApiUrl, formData, { responseType: 'blob' });
};
