/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from 'react';
import {
  Flexbox,
  Grid,
  Divider,
  FormField,
  Select,
  Button,
  RangeDatePicker,
  Option,
} from '@sede-x/shell-ds-react-framework';
import './portfoliodetails.css';
import Footer from '../Footer/Footer';
import { BackToDashboard } from '../BackToDashboard/BackToDashboard';

import dayjs, { Dayjs } from 'dayjs';
import {
  fetchSauFilterData,
  fetchCarbonBudgetLine,
  fetchevpFiltered,
  fetchSeIpuRegionFiltered,
  fetchBusinessFilterData,
} from '../../api/services';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import { ten, three } from '../../constants';
import { DefaultOptionType, RangeValue, BusinessData, TData, TradeData } from '../../Types/types';
import {
  State,
  createUrl,
  defaultUrl,
  disabledFutureDate,
  filterPortfolioData,
  getDropdownOptions,
  initialState,
  newChartData,
  replaceEvpOption,
} from './utils';
import { clearFilterFieldsList, SAU_DEFAULT_OPTION } from './constants';
import OverAllPortfolioRegionChart from '../charts/OverAllPortfolioRegionChart';
import { useAppSelector } from '../../redux/hooks';
import { DasboardContainer } from '../CarbonDashBoard/CarbonDashBoard.styles';
import { LeftDividerVertical, AllRegionChartContainer } from '../../../commonUtils/Common.styles';
import { currentYear } from '../../utils/utils';

const setField = 'setField';
const lookupData = 'lookupData';
const filteredData = 'filteredData';
const disableHandleFilter = 'disableHandleFilter';
const yearsValue = 'years';
const evpVal = 'evpVal';
const seIpuRegionVal = 'seIpuRegionVal';
const bizVal = 'bizVal';
const firstDate = 'firstDate';
const lastDate = 'lastDate';
const sau = 'sau';

type TPrimitive = string | number | boolean;
type TCustomDataType = TData[] | TradeData[];
type TLibrarayDataType = Omit<DefaultOptionType, 'children'>[] | React.ReactNode | Dayjs[];
type Action = {
  type: 'setField';
  field: keyof State;
  value: TPrimitive | TCustomDataType | TLibrarayDataType;
};

// Define your reducer function
const reducer = (state: State, action: Action): State => {
  if (action.type === setField) {
    return { ...state, [action.field]: action.value };
  }
  return state;
};
const historicalStartYear = currentYear - three;
const planningEndYear = currentYear + ten;

const fieldAndApiMethodList = [
  { key: 'SAU', field: 'sauData', fetchCall: fetchSauFilterData },
  { key: 'EVP', field: 'evpData', fetchCall: fetchevpFiltered },
  { key: 'SE_IPU_Region', field: 'seIpuRegionData', fetchCall: fetchSeIpuRegionFiltered },
  { key: 'BUSINESS', field: 'businessData', fetchCall: fetchBusinessFilterData },
];

const PortfolioDetails = () => {
  const [state, dispatchForm] = useReducer(reducer, initialState);
  const { loggedInUserRegion, userEvp, isCpmEvpFieldDisabled, isCpmRegionFieldDisabled } =
    useAppSelector((states) => states.dashboard);
  const { sauData, evpData, seIpuRegionData, businessData } = state;
  const sauOptions = getDropdownOptions({ data: sauData, key: 'SAU' });
  const evpOptions = ['ALL', ...getDropdownOptions({ data: evpData, key: 'EVP' })];
  const seIpuRegionOptions = [
    'ALL',
    ...getDropdownOptions({ data: seIpuRegionData, key: 'SE_IPU_Region' }),
  ];
  const businessOptions = [
    'ALL',
    ...getDropdownOptions({ data: businessData, key: 'REPORTING_ENTITY_NAME' }),
  ];
  const [originalFilterData, setOriginalFilterData] = useState<TradeData[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string>(loggedInUserRegion);

  const fetchFieldData = async (key: string, params?: string) => {
    try {
      const { field, fetchCall } = fieldAndApiMethodList.filter((item) => item.key === key)[0];
      const { data } =
        key === 'SAU'
          ? await fetchSauFilterData(loggedInUserRegion, userEvp)
          : await fetchCall(`api/v1/${params}`);
      dispatchForm({ type: setField, field: lookupData, value: data });
      dispatchForm({ type: setField, field: field as keyof State, value: data });
    } catch {
      notify('error', FETCH_ERROR);
    }
  };

  const clearFields = (fieldsList: string[]) => {
    fieldsList.forEach((field) => {
      dispatchForm({ type: setField, field: field as keyof State, value: '' });
    });
    dispatchForm({ type: setField, field: disableHandleFilter, value: true });
  };
  const setOptoionForAll = () => {
    // removed previous code here coz the same dispatches are happening inside initialSetUpFields method with proper values, so no meaning of setting them with 'ALL' first
    resetYear();
    initialSetUpFields();
  };

  const handleEVPChange = async (selectedOption: DefaultOptionType) => {
    const children = selectedOption.children;
    if (children === 'ALL') {
      dispatchForm({ type: setField, field: filteredData, value: state.evpData });
      setOptoionForAll();
      return;
    }
    dispatchForm({ type: setField, field: evpVal, value: selectedOption.key ?? '' });
    dispatchForm({ type: setField, field: seIpuRegionVal, value: state.seIpuRegionVal ?? 'ALL' });
    dispatchForm({ type: setField, field: bizVal, value: 'ALL' });
    const filterData = filterPortfolioData(
      state.evpData,
      selectedOption.key as string,
      state.seIpuRegionVal,
    );
    dispatchForm({ type: setField, field: filteredData, value: filterData });
    try {
      await fetchFieldData('SE_IPU_Region', createUrl('SE_IPU_Region', selectedOption));
      await fetchFieldData(
        'BUSINESS',
        createUrl('BUSINESS', selectedOption, '', state.seIpuRegionVal),
      );
    } catch {
      notify('error', FETCH_ERROR);
    }
    resetYear();
  };

  const handleSeIpuRegionChange = async (selectedOption: DefaultOptionType) => {
    const children = selectedOption.key;
    if (children === 'ALL') {
      dispatchForm({ type: setField, field: filteredData, value: state.seIpuRegionData });
      setOptoionForAll();
      return;
    }
    const filterData = filterPortfolioData(
      state.seIpuRegionData,
      state.evpVal,
      selectedOption.key as string,
    );
    dispatchForm({ type: setField, field: filteredData, value: filterData });
    dispatchForm({ type: setField, field: seIpuRegionVal, value: selectedOption.key ?? '' });
    dispatchForm({ type: setField, field: bizVal, value: 'ALL' });
    // filter latest filtered data as per selected region
    try {
      await fetchFieldData('BUSINESS', createUrl('BUSINESS', selectedOption, state.evpVal));
    } catch {
      notify('error', FETCH_ERROR);
    }
    resetYear();
  };
  const handleBusinessChange = async (selectedOption: DefaultOptionType) => {
    const children = selectedOption.children;
    if (children === 'ALL') {
      dispatchForm({ type: setField, field: filteredData, value: state.businessData });
      setOptoionForAll();
      return;
    }
    dispatchForm({ type: setField, field: bizVal, value: children ?? '' });
    const filteredVPData = [...state.businessData].filter(
      (reg: BusinessData) => reg.REPORTING_ENTITY_NAME === children,
    );
    const seIpuRegion = [...new Set(filteredVPData.map((item) => item.SE_IPU_Region))];
    const evp = [...new Set(filteredVPData.map((item) => item.EVP))];
    dispatchForm({ type: setField, field: 'seIpuRegionVal', value: seIpuRegion[0] });
    dispatchForm({ type: setField, field: 'evpVal', value: evp[0] });
    dispatchForm({ type: setField, field: filteredData, value: filteredVPData });
    resetYear();
  };

  /*to handle the start and end date and disable the handlefilter button when no dates selected */
  const handleDateChange = (e: RangeValue<Dayjs> | null) => {
    if (e !== null) {
      const startYear = e[0]?.year()?.toString();
      const endYear = e[1]?.year()?.toString();
      dispatchForm({ type: setField, field: disableHandleFilter, value: false });
      dispatchForm({ type: setField, field: firstDate, value: startYear ?? '' });
      dispatchForm({ type: setField, field: lastDate, value: endYear ?? '' });
      const data = [dayjs(startYear?.toString()), dayjs(endYear?.toString())];
      dispatchForm({ type: setField, field: yearsValue, value: data });
    } else {
      dispatchForm({ type: setField, field: disableHandleFilter, value: true });
    }
  };

  const resetYear = () => {
    dispatchForm({ type: setField, field: yearsValue, value: '' });
    dispatchForm({ type: setField, field: disableHandleFilter, value: true });
  };

  const applyFilter = () => {
    const newChartdata = originalFilterData.length
      ? newChartData({ ...state, filteredData: originalFilterData as [] })
      : newChartData(state);
    !originalFilterData.length && setOriginalFilterData(state.filteredData);
    dispatchForm({ type: setField, field: filteredData, value: newChartdata });
  };

  useEffect(() => {
    // When onChange click, empty the originalFilterData.
    if (state.disableHandleFilter) {
      setOriginalFilterData([]);
    }
  }, [state.disableHandleFilter]);

  const clearFilter = () => {
    dispatchForm({ type: setField, field: disableHandleFilter, value: true });
    dispatchForm({ type: setField, field: filteredData, value: state.masterData });
    clearFields(clearFilterFieldsList);
    setFieldsToDefault();
  };

  const fetchCarbonBudget = async () => {
    try {
      const carbonbudgetline = await fetchCarbonBudgetLine(loggedInUserRegion, userEvp);
      const carbonbudgetFilterLine = carbonbudgetline.data.filter(
        (x: { TRADE_YEAR: number }) =>
          x.TRADE_YEAR >= historicalStartYear && x.TRADE_YEAR < planningEndYear,
      );
      dispatchForm({ type: setField, field: 'masterData', value: carbonbudgetFilterLine });
      dispatchForm({ type: setField, field: filteredData, value: carbonbudgetFilterLine });
    } catch {
      notify('error', FETCH_ERROR);
    }
  };

  const setFieldsToDefault = () => {
    dispatchForm({ type: setField, field: sau, value: SAU_DEFAULT_OPTION });
    dispatchForm({
      type: setField,
      field: evpVal,
      value: evpOptions[0],
    });
    dispatchForm({
      type: setField,
      field: seIpuRegionVal,
      value: seIpuRegionOptions[0],
    });
    dispatchForm({ type: setField, field: bizVal, value: businessOptions[0] });
  };

  const initialSetUpFields = async () => {
    setFieldsToDefault();
    try {
      await fetchFieldData('EVP', defaultUrl('evp', loggedInUserRegion, userEvp));
      await fetchFieldData('SE_IPU_Region', defaultUrl('seIpuRegion', loggedInUserRegion, userEvp));
      await fetchFieldData('BUSINESS', defaultUrl('business', loggedInUserRegion, userEvp));
    } catch {
      notify('error', FETCH_ERROR);
    }
  };

  useEffect(() => {
    fetchFieldData('SAU');
    fetchCarbonBudget();
    initialSetUpFields();
  }, []);

  useEffect(() => {
    if (isCpmEvpFieldDisabled && evpOptions.length > 1) {
      const evp = evpOptions.find((option) => option.includes(userEvp)) ?? '';
      if (evp !== state.evpVal) {
        dispatchForm({ type: setField, field: evpVal, value: evp });
      }
    }
  }, [evpOptions, state.evpVal]);

  useEffect(() => {
    if (isCpmEvpFieldDisabled && seIpuRegionOptions.length > 0) {
      const region =
        seIpuRegionOptions.find((option) => option.toUpperCase().includes(loggedInUserRegion)) ??
        '';
      if (region !== state.seIpuRegionVal && region !== 'ALL') {
        dispatchForm({ type: setField, field: seIpuRegionVal, value: region });
      }
    }
  }, [seIpuRegionOptions, state.seIpuRegionVal]);
  const filteredBusinessOptions = businessOptions.filter(
    (item) => !item.includes('Wedge') && !item.includes('Europe-Other'),
  );
  return (
    <>
      <DasboardContainer>
        <Grid gap='0' style={{ gridAutoRows: 'auto' }}>
          <Flexbox>
            <BackToDashboard />
          </Flexbox>
          <Divider size='small' direction='horizontal' color='#F5F5F5' />
          <Flexbox gap='24px 24px' style={{ marginLeft: '15px', justifyContent: 'start' }}>
            <Flexbox flexDirection='column'>
              <div className='filterName'>Filters</div>
              <FormField id='sau' label='SAU' mandatory={true}>
                <Select
                  size='medium'
                  allowClear={false}
                  aria-label='Select'
                  optionLabelProp='label'
                  value={state.sau}
                  data-testid='select-sau'
                >
                  {!!sauOptions?.length &&
                    sauOptions?.map((item: string, index: number) => (
                      <Option key={item} value={index}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </FormField>
              <FormField id='evp' label='EVP'>
                <Select
                  size='medium'
                  allowClear={false}
                  value={replaceEvpOption(state.evpVal)}
                  disabled={isCpmEvpFieldDisabled}
                  onChange={(...args) => {
                    const option: DefaultOptionType = args[1] as DefaultOptionType;
                    void handleEVPChange(option);
                  }}
                  data-testid='select-evp'
                >
                  {!!evpOptions?.length &&
                    evpOptions?.map((item: string, ind: number) => (
                      <Option key={item} value={ind}>
                        {replaceEvpOption(item)}
                      </Option>
                    ))}
                </Select>
              </FormField>
              <FormField id='se-ipu-region' label='IPU Region'>
                <Select
                  size='medium'
                  allowClear={false}
                  value={state.seIpuRegionVal}
                  disabled={isCpmRegionFieldDisabled}
                  onChange={(...args) => {
                    const option: DefaultOptionType = args[1] as DefaultOptionType;
                    setSelectedRegion(String(option?.key).replace('SE', ''));
                    void handleSeIpuRegionChange(option);
                  }}
                  data-testid='select-se-ipu-region'
                >
                  {seIpuRegionOptions?.map((item: string) => (
                    <Option key={item} value={item}>
                      {item.replace('SE', '')}
                    </Option>
                  ))}
                </Select>
              </FormField>
              <FormField id='business' label='Reporting Entity'>
                <Select
                  size='medium'
                  allowClear={false}
                  value={state.bizVal}
                  onChange={(...args) => {
                    const option: DefaultOptionType = args[1] as DefaultOptionType;
                    void handleBusinessChange(option);
                  }}
                  data-testid='select-business'
                >
                  {filteredBusinessOptions?.map((business: string, ind: number) => (
                    <Option key={business} value={ind}>
                      {business}
                    </Option>
                  ))}
                </Select>
              </FormField>
              <FormField id='year' label='Year'>
                <RangeDatePicker
                  disabledDate={disabledFutureDate}
                  placeholder={['Start Year', 'End Year']}
                  picker='year'
                  format='YYYY'
                  value={state.years}
                  onChange={(e) => handleDateChange(e)}
                />
              </FormField>
              <Button
                className='getDataBtn'
                onClick={applyFilter}
                disabled={state.disableHandleFilter}
              >
                Apply Filters
              </Button>
              <Button
                className='getDataBtn'
                variant='outlined'
                style={{ marginTop: '10px' }}
                data-testid='clear-filter'
                onClick={clearFilter}
              >
                Clear Filters
              </Button>
            </Flexbox>
            <LeftDividerVertical />
            <AllRegionChartContainer>
              <OverAllPortfolioRegionChart
                carbonBudgetLineData={state.filteredData}
                selectedRegion={selectedRegion}
              />
            </AllRegionChartContainer>
          </Flexbox>
        </Grid>
      </DasboardContainer>
      <Footer />
    </>
  );
};

export default PortfolioDetails;
