import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import {
  createLatestEstimateSeries,
  createMainSeries,
  getManualLeDataForWaterFallChart,
  ShellFont,
} from '../../../utils';
import Chart from '../../Chart/Chart';
import { HighchartsReactProps } from 'highcharts-react-official';
import {
  HIGHER_THAN_PLANNED,
  LOWER_THAN_PLANNED,
  NEGATIVE_DELTA,
  POSITIVE_DELTA,
} from '../../../api/constants';
import { CHART_AXIS_STYLE, one, pipeLineGas, ten, three, two } from '../../../constants';
import { useAppSelector } from '../../../redux/hooks';

type CommodityTableRow = {
  TRADE_COMMODITY_NAME: string;
  latest_estimate: number;
  plan: number;
  delta: number;
};

HighchartsMore(Highcharts);

interface StackWaterfallProps {
  commodityRowsData: CommodityTableRow[];
  uniqueSource: string;
}

const StackWaterfall: React.FC<StackWaterfallProps> = ({ commodityRowsData, uniqueSource }) => {
  const chartRef = useRef(null);
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);

  const modifiedData = commodityRowsData.map((item) => ({
    ...item,
    delta: -item.delta,
  }));
  const updatedModifiedLeData = getManualLeDataForWaterFallChart(
    modifiedData,
    loggedInUserRegion,
    userEvp,
  );
  const getCommoditySeries = (
    commodity:
      | {
          delta: number;
          TRADE_COMMODITY_NAME: string;
          latest_estimate: number;
          plan: number;
        }
      | undefined,
    color: string,
    index: number,
  ) => {
    if (commodity) {
      const mainSeries = createMainSeries(commodity, color, index);
      const latestEstimateSeries = createLatestEstimateSeries(commodity, color);
      return [mainSeries, latestEstimateSeries];
    }
    return [];
  };

  const pipeLineData = updatedModifiedLeData.find((dt) => dt.TRADE_COMMODITY_NAME === pipeLineGas);
  const powerData = updatedModifiedLeData.find((dt) => dt.TRADE_COMMODITY_NAME === 'Power');
  const lngData = updatedModifiedLeData.find((dt) => dt.TRADE_COMMODITY_NAME === 'LNG');
  const commoditySeries = [];

  const getSeriesName = (seriesName: string) => {
    switch (seriesName) {
      case NEGATIVE_DELTA:
        return LOWER_THAN_PLANNED;
      case POSITIVE_DELTA:
        return HIGHER_THAN_PLANNED;
      default:
        return seriesName;
    }
  };

  if (pipeLineData) {
    commoditySeries.push(getCommoditySeries(pipeLineData, '#FFC600', one));
  }
  if (powerData) {
    commoditySeries.push(getCommoditySeries(powerData, '#336094', two));
  }
  if (lngData) {
    commoditySeries.push(getCommoditySeries(lngData, '#8FB753', three));
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const updatedSeries = [
    ...commoditySeries.flat().map((series) => {
      if (series && series?.data?.length > 0) {
        return {
          ...series,
          data: series.data.map((point) => {
            if (point && typeof point === 'object' && point.customColor === 'red') {
              point.dataLabels = {
                useHTML: true,
                enabled: true,
                shape: 'triangle',
                backgroundColor: 'red',
              };
            } else if (point && typeof point === 'object' && point.customColor === 'green') {
              point.dataLabels = {
                useHTML: true,
                enabled: true,
                shape: 'triangle-down',
                backgroundColor: 'green',
                borderColor: 'white',
                borderWidth: one,
              };
            }
            return point;
          }),
        };
      }
      return series;
    }),
    // Uncomment the below code once LE data is available
    // {
    //   type: 'line',
    //   name: POSITIVE_DELTA,
    //   data: [],
    //   color: 'rgba(0, 0, 0, 0)',
    //   lineWidth: 0,
    //   showInLegend: true,
    //   marker: {
    //     symbol: 'triangle',
    //     lineWidth: 3,
    //     lineColor: '#ff0000',
    //     fillColor: '#ff0000',
    //   },
    //   events: {
    //     legendItemClick() {
    //       return false;
    //     },
    //   },
    // },
    // {
    //   type: 'line',
    //   name: NEGATIVE_DELTA,
    //   data: [],
    //   showInLegend: true,
    //   color: 'rgba(0, 0, 0, 0)',
    //   lineWidth: 0,
    //   marker: {
    //     symbol: 'triangle-down',
    //     lineWidth: 3,
    //     lineColor: '#008000',
    //     fillColor: '#008000',
    //   },
    //   events: {
    //     legendItemClick() {
    //       return false;
    //     },
    //   },
    // },
  ];

  // Remove the below code once LE data is available
  const filteredSeries = updatedSeries
  .filter(item => (item.name === "Pipeline Gas" || item.name === "Power") && item.data.length > 1)
  .map(item => ({
      ...item,
      data: item.data.filter(dataItem => typeof dataItem !== 'object')
  }));

  const chartOptions = {
    chart: {
      type: 'waterfall',
      height: 500,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    title: {
      text: `Carbon Portfolio by Commodity (${currentYear})`,
      y: 20,
      align: 'left',
    },
    subtitle: {
      align: 'left',
      text: `A delta view of the current year’s carbon emissions in MTPA for each commodity.`,
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    tooltip: {
      shared: true,
      formatter() {
        const points = (this as HighchartsReactProps).points;
        let tooltip = `<span style="font-size: 12px">${
          (this as HighchartsReactProps).x
        }</span><br/>`;
        points.forEach((point: HighchartsReactProps) => {
          tooltip += `<span style="color:${point.color}">\u25CF</span> ${point?.series?.name} ${
            point.point.options.tooltipText ?? ''
          }: <b>${
            point.y !== null && point.y !== 0 && point.y ? point.y.toFixed(two) : '0.00'
          }</b></br>`;
          if (point.series.xAxis.categories[point.point.x] === '') {
            tooltip = `<span style="color:${point.color}">\u25CF</span> Delta 
            
            <b>${Math.abs(point.y).toFixed(two)}</b>`;
          }
        });
        return tooltip;
      },
    },
    xAxis: {
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: 'Commodity-wise Gap',
        y: -ten,
      },
      labels: {
        style: {
          fontFamily: 'Shell Font, Verdana, sans-serif',
          fontSize: '0.8em',
          fontWeight: '400',
          lineHeight: '16px',
          letterSpacing: '0em',
          textAlign: 'right',
          color: '#343434',
        },
      },
      categories: ['Plan', '', '', '', 'Latest Estimate'],
    },
    yAxis: {
      title: {
        useHTML: true,
        style: CHART_AXIS_STYLE,
        text: 'Carbon Emissions (MTPA)',
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        enabled: false,
        lineWidth: 1,
        lineColor: 'transparent',
        pointPadding: 0,
        // remove the below width once LE data is available
        pointWidth: 70,
        groupPadding: 0.1,
        borderColor: 'transparent',
        borderWidth: 0,
        marker: {
          enabled: true,
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
      labelFormatter() {
        const series = this as HighchartsReactProps;
        const seriesName = series.name;
        return `<text>${getSeriesName(seriesName)}</text>`;
      },
    },
    series: filteredSeries,
  };
  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refreshChart();
  }, [uniqueSource]);

  return (
    <div className='App'>
      <Chart
        chartKey={`CarbonPortfoliobyCommodity(${currentYear})`}
        ref={chartRef}
        highcharts={Highcharts}
        options={chartOptions}
        source={uniqueSource}
        key={chartKey}
      />
    </div>
  );
};

export default StackWaterfall;
