import { IFilterOptions, ITradeDataRegion } from '../../Types/types';
import { ALL, EVP_SE, EVP_SET, POWER_INTENSITY_MODEL, SE_GLOBAL_SBTI } from '../../api/constants';
import {
  GAS_COMMODITY_ORDER,
  pipeLineGas,
  PLANNING_ONE_SOURCE,
  regionOptions,
} from '../../constants';
import { IShowEvpData } from '../../redux/dashboard/dashboard.types';
import { SummedValues, TradeData } from '../charts/CarbonIntensityChart/types';
import { ProcessedDataItem } from '../charts/VolumeCarbonEmissionChart/types';

//filter target data by region
export const filterTargetDataByRegion = (
  region: string,
  carbonIntensityTarget: ITradeDataRegion[],
) => {
  const carbonIntensityData = carbonIntensityTarget;
  let firstFilteredData = [];
  if (Array.isArray(carbonIntensityData)) {
    const filteredData = carbonIntensityData.filter((item) => {
      return item.REGION.includes(region);
    });
    if (filteredData.length > 0) {
      firstFilteredData = [
        {
          year: filteredData[0].TRADE_YEAR.toString(),
          type: 'Target',
          total_value: filteredData[0].TARGET_VALUE,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    } else {
      firstFilteredData = [
        {
          year: 2030,
          type: 'Target',
          total_value: 0,
          region: region === SE_GLOBAL_SBTI ? 'Global' : region,
        },
      ];
    }
  } else {
    firstFilteredData = [{ year: '0', type: 'Target', total_value: 0 }];
  }
  return firstFilteredData;
};

export const getFilterOptions = (data: IFilterOptions[], key: keyof IFilterOptions): string[] => {
  return data
    .flatMap((val) => (val[key] !== null ? [val[key] as string] : []))
    .filter((v, index, self) => self.indexOf(v) === index);
};

export const getRegionFromSeIpu = (seIpu: string) => {
  const index = regionOptions.findIndex((item) => seIpu.includes(item));
  return index === 0 ? SE_GLOBAL_SBTI : regionOptions[index]?.toUpperCase();
};

export const getRename = (name: string) => {
  if (
    ['External Gas Sales Volumes (Pipeline)', 'Inland Sales Volumes - Pipeline Gas'].includes(name)
  ) {
    return 'Pipeline Gas';
  } else if (name === 'External Gas Sales Volumes (Renewable)') {
    return 'Renewable Gas';
  } else {
    return name;
  }
};

export const getTransformSource = (data: string): string => {
  const sourceArray = data?.split(',').map((item) => item.trim());
  const isPowerIntensityModelAndTanso = [POWER_INTENSITY_MODEL, 'Tanso'].every((item) =>
    sourceArray.includes(item),
  );
  const updatedData = Array.from(
    new Set(
      sourceArray.map((item) => {
        if (item.includes('LE_SE') || item.includes('LE_SET')) {
          return 'Reported Latest Estimate';
        } else if (item.trim() === 'Tanso') {
          return 'Approved SET Snapshots';
        } else if (item.trim() === 'Tanso_Future') {
          return 'Booked_Futures';
        } else if (item.trim() === POWER_INTENSITY_MODEL) {
          return `${POWER_INTENSITY_MODEL}${!isPowerIntensityModelAndTanso ? ',Approved SET Snapshots' : ''
            }`;
        } else if (item.trim() === PLANNING_ONE_SOURCE) {
          return 'Downstream BPC';
        }
        return item;
      }),
    ),
  );
  const transformSource = updatedData.flatMap((item) => item.split(',')).map((item) => item.trim());
  return transformSource.join(', ');
};

export const mergeAndSumPipelineGas = (processedCombinedArrayWithKey: SummedValues[]) => {
  const mergedCurrentYearPlanAndActualData = { actual: 0, plan: 0 };
  const pipelineGasEntries = processedCombinedArrayWithKey.filter(
    (item) => item.name === pipeLineGas,
  );

  if (pipelineGasEntries.length < 2) {
    pipelineGasEntries.forEach((item) => {
      if (item.currentYearPlanAndActualData) {
        mergedCurrentYearPlanAndActualData.actual += item.currentYearPlanAndActualData.actual;
        mergedCurrentYearPlanAndActualData.plan += item.currentYearPlanAndActualData.plan;
      }
    });
    return processedCombinedArrayWithKey;
  }

  const mergedPipelineGasMap = new Map<string, ProcessedDataItem>();

  const addToMap = (entry: ProcessedDataItem) => {
    if (mergedPipelineGasMap.has(entry.year)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const existingEntry = mergedPipelineGasMap.get(entry.year)!;
      existingEntry.value += entry.value;
    } else {
      mergedPipelineGasMap.set(entry.year, { ...entry });
    }
  };

  pipelineGasEntries.forEach((item) => {
    item.data.forEach(addToMap);
    if (item.currentYearPlanAndActualData) {
      mergedCurrentYearPlanAndActualData.actual += item.currentYearPlanAndActualData.actual;
      mergedCurrentYearPlanAndActualData.plan += item.currentYearPlanAndActualData.plan;
    }
  });

  const mergedPipelineGasData = Array.from(mergedPipelineGasMap.values());
  const renewableGasData = processedCombinedArrayWithKey.filter(
    (item) => item.name !== pipeLineGas,
  );
  const updatedRenewableGasData = renewableGasData.concat([
    {
      name: pipeLineGas,
      data: mergedPipelineGasData,
      currentYearPlanAndActualData: mergedCurrentYearPlanAndActualData,
    },
  ]);
  return updatedRenewableGasData;
};

// setEvpValue - this function checks user's eligibility to see SE or SET data and set the EVP field value accordingly
export const setEvpValue = (options: string[], showEvpData: IShowEvpData) => {
  if (showEvpData.showAllData) {
    return ALL;
  } else if (showEvpData.showSeData) {
    return options.find((item: string) => item.toUpperCase().includes(EVP_SE.toUpperCase())) ?? ALL;
  } else if (showEvpData.showSetData) {
    return (
      options.find((item: string) => item.toUpperCase().includes(EVP_SET.toUpperCase())) ?? ALL
    );
  }
  return ALL;
};

export const getCommodityVal = (commodityOverviewApi: TradeData[]) => {
  return [...new Set(commodityOverviewApi.map((val: TradeData) => val.TRADE_COMMODITY_NAME))].sort(
    (commodity1, commodity2) => {
      const order = ['power', 'Pipeline Gas'];
      return order.indexOf(commodity1) - order.indexOf(commodity2);
    },
  );
};

export const getOrderInCommodityPage = (updatedGroupedData: SummedValues[]) => {
  return updatedGroupedData.sort((a, b) => {
    return GAS_COMMODITY_ORDER.indexOf(a.name) - GAS_COMMODITY_ORDER.indexOf(b.name);
  });
};

export const getActualLastYearIndex = (
  resultYearArray: { year: string | number; type: string }[]
) => {
  return resultYearArray.reduce((lastIndex, item, index) =>
    item.type.replace('historical', 'actuals') === 'actuals' ? index : lastIndex, -1);
};