/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AppContext } from '../../../../../../Context/AppContext';
import { MapContainer, GeoChartContainer } from './TopCtpyBarChart.style';
import { topCounterPartyChart } from '../../../../../../api/services';
import Loader from '../../../../../Loader/Loader';
import { barChartTotalFormatter } from '../../utils';

const TopCtpyBarChart = () => {
  const { dispatch } = useContext(AppContext).appData;
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const { state: appDataState } = useContext(AppContext).appData;

  interface ChartData {
    categories: string[];
    series: Highcharts.SeriesOptionsType[];
  }

  const [chartData, setChartData] = useState<ChartData>({ categories: [], series: [] });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await topCounterPartyChart(analyticsState.filteredValues);
        setChartData(response.data);
        setIsLoading(false);
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.filteredValues]);

  useEffect(() => {
    if (appDataState.userRegion !== '') {
      analyticsDispatch({
        type: 'setFilteredValues',
        payload: {
          ...analyticsState.filteredValues, // Spread the existing filteredValues
          region: [appDataState.userRegion], // Update the region field
        },
      });
      analyticsDispatch({
        type: 'setCptyFilteredValues',
        payload: {
          ...analyticsState.filteredValues, // Spread the existing filteredValues
          region: [appDataState.userRegion], // Update the region field
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    chart: {
      type: 'bar',
      height: 800,
    },
    title: {
      text: '',
      align: 'left',
    },
    xAxis: {
      categories: chartData?.categories,
      title: {
        text: 'Counterparty',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: `Quantity (${analyticsState?.filteredValues?.uom})`,
      },
      labels: {
        formatter: (value: number) => value?.toString().replace(/[^0-9.]/g, ''),
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'black',
        },

        formatter(this: Highcharts.StackItemObject) {
          return barChartTotalFormatter(this);
        },
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
    },
    tooltip: {
      pointFormat: 'Quantity: {point.y}',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointWidth: 20,
        pointPadding: 0, // Padding between each column or bar, defaults to 0.1
        groupPadding: 0, // Padding between each value groups, in x axis units, defaults to 0.2
        dataLabels: {
          enabled: true,
          format: '{point.y:.2f}', // upto 2 decimal points
          style: {
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: 'none',
          },
        },
      },
    },
    series: chartData?.series,
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else if (!chartData?.series?.length) {
      return <p>No data available for selected filters</p>;
    } else {
      return (
        <HighchartsReact highcharts={Highcharts} options={options} data-testid='mock-highcharts' />
      );
    }
  };

  return (
    <MapContainer data-testid='map-container'>
      <GeoChartContainer data-testid='mock-highcharts'>{renderContent()}</GeoChartContainer>
    </MapContainer>
  );
};

export default TopCtpyBarChart;
