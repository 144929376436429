import React, { useState, useContext } from 'react';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import {
  ChartContainer,
  TableHeading,
  NcfToggle,
  ChartFooterText,
} from './RegionVolumeChart.style';
import {
  getCommodityName,
  generateColumns,
  WHOLESALE_CHURN_PARAMETER_NAME,
  OUT_OF_SCOPE_VOLUME_PARAMETER_NAME,
  useFetchData,
  useChartOptions,
} from './RegionVolumeChartUtils';
import Chart from '../../../Chart/Chart';
import { BaseTable, Flexbox, Grid, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { SingleRegionVolumeTableData } from '../../../../../../Types/types';
import ChartToggle from '../../../../../ReUsableComponents/PPAToggle/PPAToggle';
import Loader from '../../../../../Loader/Loader';
import { Link } from 'react-router-dom';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';

interface VolumeChartProps {
  region: string;
}

const RegionVolumeChart: React.FC<VolumeChartProps> = ({ region }) => {
  const [view, setView] = useState('Chart');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;
  const { state: ciTradeAndVolumeState } = useContext(AppContext).analyticsHome;
  const { isLoading } = useFetchData(region, view);
  const tabledata: SingleRegionVolumeTableData[] =
    ciTradeAndVolumeState.volumeByRegionTableData[region]?.data;
  const tableTotals = ciTradeAndVolumeState.volumeByRegionTableData[region]?.totals;

  const onSelectWaterfallBar = (parameter: string) => {
    setSelectedParameter(parameter);
    analyticsDispatch({
      type: 'setAnalyticsHomeDetailedViewParameter',
      payload: {
        parameterName: parameter,
        region,
      },
    });
  };
  const options = useChartOptions(region, selectedParameter, onSelectWaterfallBar);

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else if (view === 'Table') {
      return (
        <BaseTable
          columns={generateColumns(tableTotals)}
          data={tabledata}
          size='large'
          data-testid='base-table'
          stickyHeader
          maxHeight={400}
          tableOptions={tableOptions}
          width={'100%'}
          stickyFooter={true}
        />
      );
    } else {
      const dbParameterName =
        selectedParameter === WHOLESALE_CHURN_PARAMETER_NAME
          ? OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          : selectedParameter;
      return (
        <Grid gap='10px'>
          <Grid.Cell>
            <Chart options={options} footer={'Source'} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox
              justifyContent='space-between'
              alignItems='center'
              style={{ minHeight: '30px' }}
            >
              <ChartFooterText>Select a parameter to drill through</ChartFooterText>
              <Button disabled={!dbParameterName} variant='outlined' size='small'>
                <Link to={`/region-volume-details`} style={{ textDecoration: 'none' }}>
                  View Details
                </Link>
              </Button>
            </Flexbox>
          </Grid.Cell>
        </Grid>
      );
    }
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>
          {getCommodityName(analyticsState?.filteredValues?.commodity[0])} Volume For {region}
        </TableHeading>
        <Flexbox justifyContent='flex-end' alignItems='center' style={{ flex: 1 }}>
          <NcfToggle>
            <ChartToggle setView={setView} />
          </NcfToggle>
          {view === 'Table' && <DownloadCsvButton data={tabledata} fileName={'NcfVolume'} />}
        </Flexbox>
      </Flexbox>
      {renderContent()}
    </ChartContainer>
  );
};

export default RegionVolumeChart;
