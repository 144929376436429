import React from 'react';
import { columnNames } from '../../../../../CarbonDashboard/constants';
import { allCounterParty, counterPartyNcfCategory } from '../../../../api/services';
import {
  IAnalyticsHomeAction,
  IAnalyticsHomeData,
  INcfFilteredValues,
} from '../../../../Types/types';
import { StackItemObject } from 'highcharts';

export const getTableColumns = (filteredValues: INcfFilteredValues, viewTypeLevel: string) => {
  const columns = columnNames.map((col) => ({
    header: col.header === 'Quantity' ? `Quantity (${filteredValues?.uom})` : col.header,
    accessorKey: col.accessorKey,
  }));

  if (viewTypeLevel === 'allCptyLevel') {
    return [{ header: 'Counterparty Name', accessorKey: 'CounterpartyName' }, ...columns];
  } else if (viewTypeLevel === 'ncfCategoryLevel') {
    return [{ header: 'Ncf Category', accessorKey: 'NcfCategory' }, ...columns];
  } else if (viewTypeLevel === 'countryLevel') {
    return [{ header: 'Country', accessorKey: 'Country' }, ...columns];
  }
  return columnNames;
};

export const getApiData = async (
  filteredValues: INcfFilteredValues,
  viewTypeLevel: string,
  analyticsDispatch: React.Dispatch<IAnalyticsHomeAction>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsLoading(true);
  try {
    let apiFunction;
    let updatedFilter;
    let dispatchType;
    if (viewTypeLevel === 'allCptyLevel') {
      updatedFilter = { ...filteredValues, region: ['AMERICAS'] };
      dispatchType = 'setCounterpartyData';
      apiFunction = allCounterParty;
    } else {
      updatedFilter = { ...filteredValues, viewType: viewTypeLevel };
      dispatchType =
        viewTypeLevel === 'ncfCategoryLevel'
          ? 'setCounterpartyNcfCategoryData'
          : 'setCounterpartyCountryData';
      apiFunction = counterPartyNcfCategory;
    }
    const response = await apiFunction(updatedFilter);
    analyticsDispatch({
      type: dispatchType,
      payload: response.data,
    });
  } catch (error) {
    // Log error once toast feature is implemented
  }
  setIsLoading(false);
};
export const getTableData = (analyticsState: IAnalyticsHomeData, viewTypeLevel: string) => {
  if (viewTypeLevel === 'allCptyLevel') {
    return analyticsState.counterpartyData;
  } else if (viewTypeLevel === 'ncfCategoryLevel') {
    return analyticsState.counterpartyNcfCategoryData;
  } else {
    return analyticsState.counterpartyCountryData;
  }
};

export const barChartTotalFormatter = (context: StackItemObject) => {
  return context.total?.toString().replace(/[^0-9.]/g, '');
};
