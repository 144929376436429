import React, { useEffect, useContext, useState, useMemo } from 'react';
import { ChartContainer, DrillThroughView, TableHeading } from './TopCtpyTable.style';
import { BaseTable, Flexbox, Select } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import Loader from '../../../../../Loader/Loader';
import DownloadCsvButton from '../../../../../ReUsableComponents/DownloadCsvButton/DownloadCsvButton';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import { viewTypeLevelOptions } from '../../../../../../../CarbonDashboard/constants';
import { getApiData, getTableColumns, getTableData } from '../../utils';

const AllCtpyTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [viewTypeLevel, setViewTypeLevel] = useState<string>('allCptyLevel');

  const { state: analyticsState, dispatch: analyticsDispatch } =
    useContext(AppContext).analyticsHome;

  useEffect(() => {
    getApiData(analyticsState.filteredValues, viewTypeLevel, analyticsDispatch, setIsLoading);
  }, [viewTypeLevel, analyticsDispatch, analyticsState.filteredValues]);

  useEffect(() => {
    // All CPTy feature is only for AMERICAS region. So, update the region filter to AMERICAS if it is not already set.
    if (
      !analyticsState.filterOptions.region.includes('AMERICAS') ||
      analyticsState.filterOptions.region.length > 1
    ) {
      analyticsDispatch({
        type: 'setCptyFilterOptions',
        payload: {
          ...analyticsState.filterOptions,
          region: ['AMERICAS'],
        },
      });
    }
  }, [analyticsDispatch, analyticsState.filterOptions]);

  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };

  const tableData = useMemo(
    () => getTableData(analyticsState, viewTypeLevel),
    [analyticsState, viewTypeLevel],
  );

  return (
    <ChartContainer>
      <Loader loader={isLoading} message={''} />
      <Flexbox style={{ width: '100%' }} justifyContent='space-between'>
        <TableHeading>All Counterparties Table</TableHeading>
        <Flexbox
          justifyContent='flex-end'
          alignItems='center'
          style={{ flex: 1, paddingBottom: '5px' }}
        >
          <DrillThroughView>Drill Through Views</DrillThroughView>
          <Select
            allowClear={false}
            size='medium'
            value={viewTypeLevel}
            onChange={(viewLevel) => setViewTypeLevel(viewLevel)}
            style={{ width: '200px' }}
          >
            {viewTypeLevelOptions.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
          <DownloadCsvButton data={tableData} fileName={'AllCpty'} />
        </Flexbox>
      </Flexbox>

      <BaseTable
        columns={getTableColumns(analyticsState?.filteredValues, viewTypeLevel)}
        data={tableData}
        size='large'
        data-testid='base-table'
        stickyHeader={true}
        maxHeight={800}
        tableOptions={tableOptions}
        width={'100%'}
      />
    </ChartContainer>
  );
};

export default AllCtpyTable;
