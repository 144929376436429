import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { StyledDiv } from '../OverallPortfolioChart/OverallPortfolioChart.styles';
import { Toggle } from '@sede-x/shell-ds-react-framework';
import Chart from '../../Chart/Chart';
import { fetchCarbonBudget } from '../OverallPortfolioChart/utils';
import {
  ChartContainer,
  ToggleButton,
  ToggleButtonDiv,
  ToggleText,
} from '../OverallPortfolioChart/index.styles';
import { ShellFont, makeNumberFromDigits, toolTipFormatter } from '../../../utils';
import { CHART_AXIS_STYLE, five, POWER_DISCLAIMER, zero } from '../../../constants';
import { StyledContainer } from '../ScenerioModellingChart/index.styles';
import { splitDataByRegion } from './utils';
import {
  CarbonBudgetData,
  CarbonBudgetLineData,
  ChartData,
  PointsData,
  UniqueYearType,
} from '../../../Types/types';
import Loader from '../../Loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { hideSpinner, showSpinner } from '../../../redux/datavisualization/datavisualizationSlice';
import { getActualLastYearIndex } from '../../CommodityOverview/Utils';

interface OverallPortfolioChartProps {
  carbonBudgetLineData: CarbonBudgetLineData[];
  selectedRegion: string;
}

const OverAllPortfolioRegionChart: React.FC<OverallPortfolioChartProps> = ({
  carbonBudgetLineData,
  selectedRegion,
}) => {
  const dispatch = useAppDispatch();
  const { isUserGlobalOrSetAsia } = useAppSelector((state) => state.dashboard);
  const [uniqueYears, setUniqueYears] = useState<UniqueYearType[]>([]);
  const [uniqueSources, setUniqueSources] = useState<string[]>([]);
  const [showEmissionChart, setShowEmissionChart] = useState(true);
  const [carbonBudgetData, setCarbonBudgetData] = useState<CarbonBudgetData[]>([]);
  const [indexOfLastActuals, setIndexOfLastActuals] = useState(-1);
  const point5 = 0.5;

  useEffect(() => {
    if (carbonBudgetData.length > 0) {
      const {
        uniqueSources: newUniqueSources,
        uniqueYears: newUniqueYears,
      } = fetchCarbonBudget(carbonBudgetData);
      setUniqueSources(newUniqueSources);
      setUniqueYears(newUniqueYears);
    }
  }, [carbonBudgetData]);
  useEffect(() => {
    fetchCarbonBudget([]);
    setUniqueSources([]);
    setUniqueYears([]);
    setCarbonBudgetData([]);
  }, []);

  useEffect(() => {
    dispatch(showSpinner());
    setCarbonBudgetData([]);
    const updatedCarbonBudgetData = carbonBudgetLineData.map((data) => {
      return data;
    });
    setCarbonBudgetData(updatedCarbonBudgetData);
    updatedCarbonBudgetData.length > 0 && dispatch(hideSpinner());
  }, [carbonBudgetLineData, dispatch]);

  useEffect(() => {
    setIndexOfLastActuals(getActualLastYearIndex(uniqueYears));
  }, [uniqueYears]);

  const options: Highcharts.Options = {
    chart: {
      type: 'area',
      height: makeNumberFromDigits([five, zero, zero]),
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    title: {
      text: 'Portfolio Overview',
      align: 'left',
      y: 20,
    },
    subtitle: {
      align: 'left',
      text: `${!showEmissionChart ? 'Net Absolute Emission' : 'Sales Volume'} Actuals vs Plan.`,
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px', // Adjust the font size here as per your requirement
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: !showEmissionChart ? 'Carbon Emission (MTPA)' : 'Volume (TWh)',
        style: CHART_AXIS_STYLE,
      },
    },

    xAxis: {
      categories: uniqueYears.map((x: { year: number }) => x.year.toString()),
      plotBands:
        indexOfLastActuals !== -1
          ? [
            {
              color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
              from: -point5,
              to: indexOfLastActuals,
              label: {
                text: 'Actuals',
                style: {
                  fontFamily: ShellFont,
                  fontSize: '11px',
                  fontWeight: '600',
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  textAlign: 'right',
                  color: '#343434',
                },
                verticalAlign: 'bottom', // Label below the y axis
                y: 40, // Adjust label position
              },
            },

            {
              color: 'white', // Gray background for historical section
              from: indexOfLastActuals,
              label: {
                text: 'Plan',
                style: {
                  fontFamily: ShellFont,
                  fontSize: '11px',
                  fontWeight: '600',
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  textAlign: 'right',
                  color: '#343434',
                },
                verticalAlign: 'bottom', // Label below the y axis
                y: 40, // Adjust label position
              },
            },
          ]
          : [
            {
              color: 'white', // Gray background for historical section
              from: -point5,
              to: uniqueYears.length - 1 + point5, // To final year
              label: {
                text: 'Plan',
                style: {
                  fontFamily: ShellFont,
                  fontSize: '11px',
                  fontWeight: '600',
                  lineHeight: '16px',
                  letterSpacing: '0em',
                  textAlign: 'right',
                  color: '#343434',
                },
                verticalAlign: 'bottom', // Label below the y axis
                y: 40, // Adjust label position
              },
            },
          ],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: 'transparent',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series: splitDataByRegion(
      carbonBudgetData,
      uniqueYears,
      showEmissionChart,
      selectedRegion,
    ) as Highcharts.SeriesAreaOptions[],
  };

  const toggleEmissionChart = () => {
    setShowEmissionChart(!showEmissionChart);
  };

  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    refreshChart();
  }, [uniqueSources]);
  return (
    <>
      <Loader />
      <StyledContainer>
        <ToggleButtonDiv>
          <ToggleButton>
            <Toggle
              label='Volume'
              mirrored
              data-testid='toggleButton-overallPortfolio'
              onChange={() => toggleEmissionChart()}
            />
          </ToggleButton>
          <ToggleText>Emissions</ToggleText>
        </ToggleButtonDiv>
        <StyledDiv>
          <ChartContainer>
            <Chart
              highcharts={Highcharts}
              options={options}
              source={uniqueSources.join(', ')}
              data-testid='chart'
              key={chartKey}
              chartKey='OverallPortfolio'
              {...(isUserGlobalOrSetAsia && { disclaimer: [POWER_DISCLAIMER] })}
            />
          </ChartContainer>
        </StyledDiv>
      </StyledContainer>
    </>
  );
};

export default OverAllPortfolioRegionChart;
